
import { Options, Prop, Watch } from "vue-property-decorator";
import DefaultLayout from "@/layout/DefaultLayout.vue";
import Question from "@/components/Pages/TheoryQuestions/Question.vue";
import { namespace } from "s-vuex-class";
import { mixins } from "vue-class-component";
import NavigationMixin from "@/mixins/NavigationMixin";
import { QUESTION_FORMAT_MAP } from "@/maps/QuestionFormatsMap";
import isArray from "lodash/isArray";
import QuestionAnswersMixin from "@/mixins/TheoryQuestions/QuestionAnswersMixin";
import BackButton from "@/components/Pages/TheoryQuestions/BackButton.vue";
import FavoriteActionMixin from "@/mixins/FavoriteActionMixin";
import FavoriteButton from "@/components/FavoriteButton.vue";
import QuestionHint from "@/components/QuestionHint.vue";
import NextButton from "@/components/NextButton.vue";
import PrevButton from "@/components/PrevButton.vue";
import { ICrudOptions } from "@/interfaces/ICrudOptions";
import shuffle from "lodash/shuffle";

const TheoryQuestionGroupModule = namespace("theory-question-groups");
const StudentEducationModule = namespace("student-education");
const TheoryQuestionsModule = namespace("theory-questions");
const StudentQuestionAnswersModule = namespace("student-question-answers");

@Options({
  components: {
    PrevButton,
    NextButton,
    QuestionHint,
    FavoriteButton,
    BackButton,
    Question,
    DefaultLayout,
  },
})
export default class TheoryQuestionGroup extends mixins(NavigationMixin, QuestionAnswersMixin, FavoriteActionMixin) {
  private questionsFormatMap: any = QUESTION_FORMAT_MAP;
  @Prop()
  public theoryQuestionGroupId!: any;
  @Prop()
  public selectedQuestionId!: any;

  @TheoryQuestionsModule.Action("resetSave")
  public resetTheoryQuestionsQuestion: any;

  @TheoryQuestionsModule.Action("setMarkFavouriteSuccess")
  public setMarkFavouriteSuccessAction: any;

  @StudentQuestionAnswersModule.Action("create")
  public answerAction: any;

  @StudentQuestionAnswersModule.Action("resetSave")
  public resetAnswerQuestion: any;

  @StudentQuestionAnswersModule.Getter("getSuccess")
  public answerSuccess: any;

  @StudentQuestionAnswersModule.Getter("getIsLoading")
  public loadingAnswer: any;

  @StudentEducationModule.Getter("withTheory/getDataList")
  public studentEducations: any;

  @TheoryQuestionGroupModule.Action("findQuestionsByGroup")
  public findQuestionsAction!: (options: ICrudOptions) => Promise<void>;

  @TheoryQuestionGroupModule.Action("resetQuestions")
  public resetQuestionsAction: any;

  @TheoryQuestionGroupModule.Action("setQuestions")
  public setQuestionsAction: any;

  @TheoryQuestionGroupModule.Getter("getGroups")
  public groups: any;

  @TheoryQuestionGroupModule.Getter("getQuestions")
  public getQuestions: any;

  @TheoryQuestionGroupModule.Getter("getIsLoading")
  public questionsLoading: any;

  private showSolutionButton = false;
  private showCorrectAnswers = false;
  protected question: any = {};

  private get questions() {
    return shuffle(this.getQuestions);
  }

  get selectedQuestionIndex(): number {
    return isArray(this.questions) ? this.questions.findIndex((q: any) => q.id == this.question.id) : -1;
  }

  get group(): any {
    for (const g in this.groups) {
      if (g === this.theoryQuestionGroupId) {
        return {
          id: g,
          name: QUESTION_FORMAT_MAP[g],
          count: this.groups[g],
        };
      }
    }
    return "";
  }

  get loading(): boolean {
    return this.loadingAnswer || this.questionsLoading;
  }

  private isTheoryQuestionGroupIdValid(theoryQuestionGroupId: string) {
    for (const g in this.groups) {
      if (g === theoryQuestionGroupId) {
        return true;
      }
    }
    return false;
  }

  private selectQuestion(question: any): void {
    this.showCorrectAnswers = false;
    this.reset();
    this.question = question;
  }

  private goToNext(): void {
    if (this.selectedQuestionIndex >= this.questions.length - 1) {
      return;
    }
    this.selectQuestion(this.questions[this.selectedQuestionIndex + 1]);
  }
  private goToPrev(): void {
    if (this.selectedQuestionIndex <= 0) {
      return;
    }
    this.selectQuestion(this.questions[this.selectedQuestionIndex - 1]);
  }

  private async loadQuestions(): Promise<any> {
    if (this.theoryQuestionGroupId === "NEW_QUESTIONS") {
      await this.findQuestionsAction({
        resource: "theory-questions/next-version",
        descriptionField: "",
      });
    } else {
      await this.findQuestionsAction({
        resource: `question-groups/${this.theoryQuestionGroupId}/questions`,
        hideSuccessMessage: true,
        descriptionField: "",
      });
    }
  }

  private showSolutions(): void {
    this.showSolutionButton = false;
    this.showCorrectAnswers = true;
  }

  @Watch("theoryQuestionGroupId", { immediate: true, deep: true })
  private onTheoryQuestionGroupChange(theoryQuestionGroupId: any): void {
    if (!this.isTheoryQuestionGroupIdValid(theoryQuestionGroupId)) {
      this.goBack();
    }
    if (theoryQuestionGroupId) {
      this.loadQuestions();
    }
  }

  @Watch("questions", { immediate: true, deep: false })
  private onQuestionsChange(questions: any, oldQuestions: any): void {
    if (isArray(questions) && questions.length > 0) {
      this.question = questions[0];
    }
  }

  @Watch("studentEducations", { immediate: true, deep: true })
  private onStudentEducationsChange(studentEducations: any): void {
    if (isArray(studentEducations) && studentEducations.length > 0) {
      this.studentEducation = studentEducations[0].id;
    }
  }

  private reset(): void {
    this.resetTheoryQuestionsQuestion();
    this.setMarkFavouriteSuccessAction(false);
  }

  public beforeUnmount(): void {
    this.reset();
    this.resetQuestionsAction();
  }
}
