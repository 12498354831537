
import { Options, Vue, Watch } from "vue-property-decorator";
import MostDifficultQuestions from "@/components/Pages/Playground/Questions/MostDifficultQuestions.vue";
import DefaultLayout from "@/layout/DefaultLayout.vue";
import TheoryQuestionGroup from "@/components/Pages/Playground/Questions/TheoryQuestionGroup.vue";
import ForwardHeader from "@/components/Headers/ForwardHeader.vue";

@Options({
  components: {
    ForwardHeader,
    TheoryQuestionGroup,
    DefaultLayout,
    MostDifficultQuestions,
  },
})
export default class TheoryQuestionGroups extends Vue {
  private theoryQuestionGroup = "";

  @Watch("$route", { immediate: true, deep: true })
  private onRouteChange(newVal: any): void {
    this.theoryQuestionGroup = newVal.params.id;
  }
}
